.hidden { display:none; }

.wrapper{
    border: 3px solid #333;
    padding: 5px;
}

.table_data thead{
    font-weight: bold;
}

.nb{
    font-weight: bold;
    color: red;
    text-align: center;
}

.footer{
   color:#ffffff;background:#1c2e4a;margin-top:200px;margin-bottom:-40px;padding:10px;
   text-align: center;


}

#logo{
    margin: auto 0;
} 

.tb thead{
    text-align: center;
}



.card-counter {
    box-shadow: 2px 2px 10px #DADADA;
    margin: 5px;
    padding: 20px 10px;
    background-color: #fff;
    height: 100px;
    border-radius: 5px;
    transition: .3s linear all;
}

.card-counter:hover {
    box-shadow: 4px 4px 20px #DADADA;
    transition: .3s linear all;
}

.card-counter.primary {
    background-color: #007bff;
    color: #FFF;
}

.card-counter.danger {
    background-color: #ef5350;
    color: #FFF;
}

.card-counter.success {
    background-color: #66bb6a;
    color: #FFF;
}

.card-counter.info {
    background-color: #26c6da;
    color: #FFF;
}

.card-counter i {
    font-size: 5em;
    opacity: 0.2;
}

.card-counter .count-numbers {
    position: absolute;
    right: 35px;
    top: 20px;
    font-size: 32px;
    display: block;
}

.card-counter .count-name {
    position: absolute;
    right: 35px;
    top: 65px;
    font-style: italic;
    text-transform: capitalize;
    opacity: 0.5;
    display: block;
    font-size: 18px;
}

.fc-toolbar-title{
    color: #333 !important;
}

.ng-star-inserted th{
    border: 2px solid;
    border-left: 2px;
}

.ng-star-inserted1 td{
    border: 2px solid;
    border-left: 2px;
}




