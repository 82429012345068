.hidden { display:none; }


.w-100{width: 100%;}


.card h6,
h2 {
    color: #fff !important;
}

.order-card {
    color: #fff !important;
}

.bg-c-blue {
    background: linear-gradient(45deg, #4099ff, #73b4ff);
}

.bg-c-green {
    background: linear-gradient(45deg, #2ed8b6, #59e0c5);
}

.bg-c-yellow {
    background: linear-gradient(45deg, #FFB64D, #ffcb80);
}

.btns button {
    width: 10rem;
}

.bg-c-pink {
    background: linear-gradient(45deg, #FF5370, #ff869a);
}

.card {
    color: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
    box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
    border: none;
    margin-bottom: 30px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.card .card-block {
    padding: 25px;
}

.order-card i {
    font-size: 26px;
}

.f-left {
    float: left;
}

.f-right {
    float: right;
}

#table{
    height: 100vh;
    min-height: 100vh;
    overflow-y: scroll;
}

.fc-toolbar-title{
    color: #333 !important;
}



